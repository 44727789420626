import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import ContactForm from '../components/ContactForm'
import Squiggle from '../components/Squiggle'

const MailingListPage = () => (
  <Layout>
    <SEO title="Get Notified" />
    <h1>Get Notified</h1>
    <p>
      By signing up here, you'll never miss when the store changes does.
    </p>

    <Squiggle
      type={2}
      color={'#5601FF'}
      style={{
        bottom: '20%',
        left: '-50pt',
      }}
    />

    <br />

    <ContactForm />
  </Layout>
)

export default MailingListPage
