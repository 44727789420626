import React, { useState } from 'react'
import { Mail } from 'react-feather'
import { navigate } from 'gatsby'

import './ContactForm.css'

const CONTACT_FORM_NAME = 'mailing-list-signup'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactForm = () => {
  const [email, setEmail] = useState('')

  const onSubmit = async e => {
    e.preventDefault()

    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': CONTACT_FORM_NAME, email }),
      })

      navigate('/mailinglist-success')
    } catch {
      alert('There was a problem in processing your request. Try again?')
    }
  }

  return (
    <form
      name={CONTACT_FORM_NAME}
      onSubmit={onSubmit}
      data-netlify="true"
      className="contact-form"
    >
      <input type="hidden" name="form-name" value={CONTACT_FORM_NAME} />

      <p>
        <label>
          <Mail />
          <input
            type="email"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="example@gmail.com"
          />
        </label>
      </p>

      <p>
        <button className="btn btn-primary" type="submit">
          Sign Up
        </button>
      </p>
    </form>
  )
}

export default ContactForm
